function audioFile() {
  // for each audioquote component, if there is more than one in the same page
  setTimeout(() => {
    $(".cp-audioquote").each(function (e) {
      const $this = $(this);
      const $togglePlay = $this.find(".cp-audioquote__player--playBtn");
      const player = $this.find("audio").get(0);
      const progressBar = $this.find(".cp-audioquote__player--playhead");
      const secondPLayBtn = $(".secondPLayBtn");
      secondPLayBtn.on("click", function () {
        $togglePlay.trigger("click");
      });
      $togglePlay.on("click", function () {
        if (player.paused) {
          player.play();
          $this.find(".cp-audioquote__player").addClass("is-playing");
        } else {
          player.pause();
          $this.find(".cp-audioquote__player").removeClass("is-playing");
        }
      });

      // when the audio finish, reset all
      player.addEventListener(
        "ended",
        function () {
          $(".cp-audioquote__player").removeClass("is-playing");
          // reset to zero the progress
          progressBar.css("width", "0%");
          // time at zero
          player.currentTime = 0;
        },
        true
      );

      // set total duration of the video
      player.addEventListener(
        "canplaythrough",
        function () {
          // insert total duration into the page
          const totalLength = calculateTotalValue(player.duration);
          $this.find(".duration").html(totalLength);
        },
        false
      );

      // calculate total length of the audio
      function calculateTotalValue(length) {
        const minutes = Math.floor(length / 60);
        const seconds_int = length - minutes * 60;
        if (seconds_int < 10) {
          seconds_int = "0" + seconds_int;
        }
        const seconds_str = seconds_int.toString();
        const seconds = seconds_str.substr(0, 2);
        const time = minutes + ":" + seconds;
        return time;
      }

      // Update the progress bar
      function updateProgressBar() {
        // Work out how much of the media has played via the duration and currentTime parameters
        const percentage = Math.floor(
          (100 / player.duration) * player.currentTime
        );
        // Update the progress bar's value
        progressBar.css("width", percentage + "%");
        // Update the progress bar's text
        const currentTime = calculateCurrentValue(player.currentTime);
        $this.find(".playhead").html(currentTime);
      }

      function calculateCurrentValue(currentTime) {
        let current_hour = parseInt(currentTime / 3600) % 24,
          current_minute = parseInt(currentTime / 60) % 60,
          current_seconds_long = currentTime % 60,
          current_seconds = current_seconds_long.toFixed(),
          current_time =
            (current_minute < 10 ? "" + current_minute : current_minute) +
            ":" +
            (current_seconds < 10 ? "0" + current_seconds : current_seconds);
        return current_time;
      }

      // Add a listener for the timeupdate event so we can update the progress bar
      player.addEventListener("timeupdate", updateProgressBar, false);

      $this.find(".cp-audioquote__player--track").on("click", function (e) {
        if (player.src) {
          const percent = e.offsetX / this.offsetWidth;
          player.currentTime = percent * player.duration;
          // update progress bar
          progressBar.css("width", Math.floor(percent / 100) + "%");
        }
      });
    });
  }, 1000);
}

function progressbar() {
  const progressBar = document.querySelector(".progress-bar");
  const progressBarOverlay = document.querySelector(".progress-bar-overlay");
  const progressBarText = document.querySelector(".progress-bar-text");

  function updateProgressBar(progressPercentage) {
    progressBar.style.setProperty("--value", progressPercentage / 100);
    progressBarOverlay.style.setProperty("--value", progressPercentage / 100);
    progressBarText.textContent = `${progressPercentage}%`;
  }
  updateProgressBar(50);
  // Call the updateProgressBar function with the progress percentage whenever you need to update the progress bar
}
function circularProgressBar() {
  var totalProgress, progress;
  const circles = document.querySelectorAll(".progress");
  for (var i = 0; i < circles.length; i++) {
    totalProgress = circles[i]
      .querySelector("circle")
      .getAttribute("stroke-dasharray");
    progress = circles[i].parentElement.getAttribute("data-percent");
  }
}
